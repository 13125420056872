<template>
    <EditableArea
        :value="spaceDescription"
        :apiURL="'/spaces/' + this.$route.params.sid"
        successMessage="Space description successfully updated!"
        editAttr="description"
        editAttrFullName="space description"
        color="white"
        defaultValue="Description not available for this space"
        :showEditIcon="isSpaceAdmin"
        :id="parseInt($route.params.sid, 10)"
        :finally="
            () => {
                this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)
            }
        "
    ></EditableArea>
</template>

<script>
import { mapGetters } from 'vuex'
const EditableArea = () => import('@/components/EditableArea')

export default {
    name: 'TheSpaceEditableDescriptionField',
    components: {
        EditableArea
    },
    computed: {
        spaceDescription() {
            return this.$store.getters['spaceStore/spaceDescriptionById'](this.$route.params.sid)
        },
        ...mapGetters('spaceStore', ['isSpaceAdmin'])
    }
}
</script>

var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EditableArea", {
    attrs: {
      value: _vm.spaceDescription,
      apiURL: "/spaces/" + this.$route.params.sid,
      successMessage: "Space description successfully updated!",
      editAttr: "description",
      editAttrFullName: "space description",
      color: "white",
      defaultValue: "Description not available for this space",
      showEditIcon: _vm.isSpaceAdmin,
      id: parseInt(_vm.$route.params.sid, 10),
      finally: function() {
        this$1.$store.dispatch(
          "orgStore/fetchOrgSpaces",
          this$1.$route.params.oid
        )
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }